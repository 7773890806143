.custom-carousel{
    display: flex;
    width: 336px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-8, 8px);
}

.custom-carousel-cards{
    /* display: inline-flex; */
    height: 216.32px;
    align-items: flex-start;
    gap: var(--spacing-16, 16px);
    flex-shrink: 0;
}

.custom-carousel-bottom{
    display: flex;
    width: 312px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.custom-carousel-bottom-child{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.custom-carousel-bottom-item{
    height: var(--spacing-4, 4px);
    flex: 1 0 0;
    border-radius: var(--radius-4, 4px);
    background: rgba(139, 142, 193, 0.50);
}

.custom-carousel-bottom-item.active{
    background: var(--secondary-icon-medium, #76A8FF);
}




[class^="slideInner___"] {
    position: initial !important ;
}